import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Navbar, Container, Row, Col, Form, Button, Card, Alert, Spinner, Image  } from 'react-bootstrap';
import './Checkout.css';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51QBNoFQpdwclSJevHQ0WWOtaiKxltgWreGOUuhD7nUcnpI3uRLTAuKmhEM3QNghiMr9b3s7ic6vHq2vo0g8C9uWN00tgfNnx7f');
//const stripePromise = loadStripe('pk_live_51QBJzCJUrboMFJ6RXrC3tydocpvO6eUKvQlemh5EDw3I58xHVmPtf1DUsajpgE97ljfThvGsB1dRLWbrveJDve8T00f01CeSt5');

const CheckoutForm = ({totalCost}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState({
    line1: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [cardLoaded, setCardLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setCardLoaded(true);
    },10000);
  },[setCardLoaded]);

  const cardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        letterSpacing: '0.025em',
        lineHeight: '24px',
        padding: '10px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        email,
        address,
      },
    });

    if (error) {
      setError(error.message);
      return;
    }

    const response = await fetch('https://mobileyes-worker2.mobileyesappdev.workers.dev/process-payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount:totalCost, currency:'USD', paymentMethodId:paymentMethod.id, email }),
    });

    const paymentResult = await response.json();

    if (paymentResult.success) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(paymentResult.paymentIntent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name,
            email,
            address,
          },
        },
      });
      if(paymentIntent.status === 'succeeded'){
        setPaymentSuccess(true);
      }
      if(error){
        alert('error ', error);
      }
      
    } else {
      setError(paymentResult.message);
    }

    setLoading(false);
  };

  return (<><Container style={{display:(!cardLoaded)?'flex':'none'}} className="align-items-center justify-content-center"><Spinner/></Container>
  <Row style={{display:(cardLoaded)?'flex':'none'}} className="pt-4 pb-4 align-items-center  justify-content-center">
    <Col xs={12} md={6}>
    <Card>
    <Card.Body>
    <Form onSubmit={handleSubmit} className="payment-form">
      {!paymentSuccess && <>
      <h3>Payment Information</h3>
      <div className="card-element mb-3">
        <CardElement options={cardElementOptions} />
      </div>
      <h3>Billing Information</h3>
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="line1">
        <Form.Label>Address Line 1</Form.Label>
        <Form.Control
          type="text"
          value={address.line1}
          onChange={(e) => setAddress({ ...address, line1: e.target.value })}
          required
        />
      </Form.Group>
      <div className="d-flex">
        <Form.Group controlId="city" className="mr-1">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            value={address.city}
            onChange={(e) => setAddress({ ...address, city: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group controlId="state" className="mr-1">
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            value={address.state}
            onChange={(e) => setAddress({ ...address, state: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group controlId="postal_code">
          <Form.Label>Zip Code</Form.Label>
          <Form.Control
            type="text"
            value={address.postal_code}
            onChange={(e) => setAddress({ ...address, postal_code: e.target.value })}
            required
          />
        </Form.Group>
      </div>
      <Form.Group controlId="country">
        <Form.Label>Country</Form.Label>
        <Form.Control
          as="select"
          value={address.country}
          onChange={(e) => setAddress({ ...address, country: e.target.value })}
        >
          <option value="US">United States</option>
          <option value="CA">Canada</option>
        </Form.Control>
      </Form.Group>
      <Button type="submit" disabled={!stripe || loading} className="mt-3 w-100 submit-button">
        {loading ? 'Processing...' : 'Submit Payment'}
      </Button>
      </>}

      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      {paymentSuccess && <>
          <Alert variant="success" className="mt-3">Payment Successful! an receipt email has been sent to this email: {email}</Alert>
          <Button onClick={() => navigate('/')} className="mt-3 w-100 submit-button">
            Go Back to Home Page
          </Button>
      </>}
    </Form>
    </Card.Body>
    </Card>
    </Col>
    </Row>
    </>
  );
};

export const Checkout = () => {
  const navigate = useNavigate();
  const [numberOfMonths, setNumberOfMonths] = useState(0);
  return (<>
    <Navbar style={{backgroundColor:'black'}} expand="lg">
        <Container>
          <Navbar.Brand onClick={() => navigate('/')} style={{color:'white'}}><Image src={require("../assets/app-icon.png")} style={{height:80,marginRight:10}} /><span style={{fontSize:22,color:'#9aeba6',fontWeight:'bold'}}>Mobileyes</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
    </Navbar>
    <Container className="payment-page mt-2 mb-5">
      <h1 className="text-center">Mobileyes Payment</h1>
      <Row className="align-items-center justify-content-center">
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <Card.Text>Claim 50% discount for the live lawyer feater upon our launch!</Card.Text>
              <Form.Group controlId="formEmail">
                    <Form.Label>Enter the number of months you would like to purchase at a discount</Form.Label>
                    <Form.Control type="number" placeholder="Number of months" value={numberOfMonths} onChange={input => setNumberOfMonths(input.target.value)} />
              </Form.Group>
              <Card.Text className="mt-3"><b>Total Cost:</b> ${(9.99 * numberOfMonths)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Elements stripe={stripePromise}>
        <CheckoutForm totalCost={(9.99 * numberOfMonths)} />
      </Elements>
    </Container>
    </>
  );
}