import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col, Button, Image } from 'react-bootstrap';
import './Cover.css';

export const CoverSection = () => {
  // const [name, setName] = useState('');
  // const [message, setMessage] = useState('');
  // const [email, setEmail] = useState('');
  const navigate = useNavigate();

  // const handleSendEmail = async(e) => {
  //   e.preventDefault();
  //   let response = await fetch('https://mobileyes-worker2.mobileyesappdev.workers.dev/send-email', {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({ toEmail:'eliezerkombe@gmail.com', subject:`Message from ${name}`, message:`${message}<br/><br/>${name}<br/>${email}` })
  //   });

  //   let result = response.json();
  //   alert(JSON.stringify(result));
    
  // }

  return <>
  <div className="cover-section">
    <Navbar className="cover-navbar" bg="transparent" expand="lg">
      <Container>
        <Navbar.Brand onClick={() => navigate('/')}><Image src={require("../assets/app-icon.png")} style={{height:80,marginRight:10}} /><span style={{fontSize:22,color:'#9aeba6',fontWeight:'bold'}}>Mobileyes</span></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{color:'white'}}/>
        <Navbar.Collapse id="basic-navbar-nav" style={{color:'white'}}>
          <Nav className="ms-auto">
            <Nav.Link onClick={() => navigate('/')} style={{color:'white'}}>Home</Nav.Link>
            <Nav.Link href="#about" style={{color:'white'}}>About</Nav.Link>
            <Nav.Link href="#features" style={{color:'white'}}>Features</Nav.Link>
            <Nav.Link href="#contact" style={{color:'white'}}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container className="text-center d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <section className="two-column-section align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
              <div>
                <Image className="d-none d-md-block" src={require("../assets/CoverImage.png")} style={{height:350}}/>
                <Image className="d-block d-md-none" src={require("../assets/CoverImage.png")} style={{height:250}}/>
              </div>
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center">
              <div className="content">
                <p style={{fontSize:16, color:'white'}}>Welcome to the MobilEyes Discount Page for Testers.
                By signing up through this link, you will
enjoy discounted pricing and become a founding subscriber of the MobilEyes App. This
membership offers you exclusive benefits, including access to prize and cash drawings, paid
opportunities, special discounts, and unique product coupons and merch that will not
available to the other subscribers. We are dedicated to expressing our gratitude to our
founding subscribers, who are instrumental in shaping this innovative technology to ensure
its effectiveness and value for you.
                  This 50% discount provides you with early access to the
official version. </p>
                <Button onClick={() => navigate('/checkout')}>Claim your discount today</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  </div>
  <div>
  <section className="two-column-section p-4" style={{ color: 'black' }}>
        <Container>
          <Row>
            <Col xs={12} md={6} className="d-flex align-items-center">
              <div className="content">
                <h2>App is available for iphone users!</h2>
                  <a href="https://apps.apple.com/us/app/mobileyes-app/id6736928302">
                    <Image src={require("../assets/DownloadIphoneApp.webp")} style={{height:50}} />
                  </a>
                  <p style={{ fontSize: '16px', textAlign:'justify' }}>
                  Download app today</p>
              </div>
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center">
              <div className="content">
                <h2>Android App awaiting review.</h2>
                  <a href="https://apps.apple.com/us/app/mobileyes-app/id6736928302">
                    <Image src={require("../assets/DownloadAndroidApp.webp")} style={{height:50}} />
                  </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="about" className="two-column-section p-4" style={{ color: 'white', backgroundColor:'black' }}>
        <Container>
          <Row>
            <Col xs={12} md={6} className="d-flex align-items-center">
              <div className="content">
                <h2>About Us</h2>
                <p style={{ fontSize: '16px', textAlign:'justify' }}>
                  MobilEyes is a platform designed to protect citizen's right from infringement by law enforcement or any other governing bodies that could violate one's constitutional rights.
                  It also provides 100% transparency for the community through our ratings and rewards program allowing users to look up previous officer conduct while he's searching your credentials.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
              <Image src={require("../assets/Golive_Screen.png")} style={{height:350,alignSelf:'center'}} />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="features" className="two-column-section p-4">
        <Container>
          <Row>
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
              <Image src={require("../assets/livestream_screen.png")} style={{height:350}} />
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center">
              <div className="content" style={{ fontSize: '16px', textAlign:'justify' }}>
                <h2>Live streaming feature</h2>
                <p>Introducing the streaming feature designed for outstaning efficiency and full accountability. It offers real-time streaming to our platform and all linked social media accounts. This adaptable feature maximizes usability reaching a broad audience for increased accountability. It ensures a seemless experience while enabling us to implement preventative measures, reassuring officers that the world is indeed observing, along side our legal live lawyers prepared to effectively assist in any necessary situation.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="two-column-section p-4" style={{ color: 'white', backgroundColor:'black' }}>
        <Container>
          <Row>
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
              <div className="content">
                <h2>Bumpersticker</h2>
                <p style={{ fontSize: '16px', textAlign:'justify' }}>
                  The Bumper sticker is a beacon for law enforcement allowing them to have sufficient warning that you are protected by the MobilEyes ap and that the user has legal mitigation at the push of a button if their rights are infringed upon. We are currently working with officials in the top of the chain to facilitate cooperation for the safety of the citizens. Being a citizen owned citizen run platform, we have made sure that the safety of the American citizens and the preservation of their civil rights while effectively preventing the burden that this problem has on the Taxpayer.
                </p>
              </div>
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
              <Image src={require('../assets/CoverImage.png')} style={{height:350}}/>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="two-column-section p-4" style={{ color: 'black' }}>
        <Container>
          <Row>
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
              <Image src={require('../assets/Copwatch_Screen.png')} style={{height:350}} />
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center">
              <div className="content">
                <h2>Copwatch feature</h2>
                <p style={{ fontSize: '16px', textAlign:'justify' }}>
                The copwatch feature is designed to ensure that officers receive thorough training on constitutional rights. Supplemented by training modules and resources aimed at fostering an effective community outreach model developed by citizens. This feature incorporates user-generated ratings following each encounter. This serves as navigational tool for the allocation of funding dedicated to training, mental health support and suicide prevention hotlines, among other resources. Furthermore, it articulates a nationwide mission statement that guides ofices in engagin with the community in meaningful ways. This is also essential for their funding initiatives and job responsibilities. Additionally, it offers effective training in mental health identification, benefiting both the community and their peers.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <footer id="contact" className="footer pt-4 pb-4" style={{color:'white', backgroundColor:'black'}}>
        <Container>
          <Row>
            
              {/* <Card>
                <Card.Body>
                <h2>Contact Us</h2>
                <Form>
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" value={name} onChange={input => setName(input.target.value)} />
                  </Form.Group>
        
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" value={email} onChange={input => setEmail(input.target.value)} />
                  </Form.Group>
        
                  <Form.Group controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Enter your message" value={message} onChange={input => setMessage(input.target.value)} />
                  </Form.Group>
                  <Form.Group>
                    <Button onClick={handleSendEmail} className="mt-3 mb-3 w-100" variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
                </Card.Body>
              </Card> */}
            
            <Col xs={12} md={6} className="footer-info">
              <h2>Thank you!</h2>
              <p>
                Thank you to all our supporters both nationally and internationally. We are dedicated to delivering an effective and professional digital service that will significantly enhance the conditions faced by America and many other countries.
              </p>
            </Col>
            <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
              <Image src={require('../assets/CashTag.webp')} style={{height:250}} />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <p className="footer-bottom">&copy; {`${new Date().getFullYear()}`} MobilEyes LLC. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
    </>
};