import React from 'react';
import { CoverSection } from './pages/Cover';
import { Checkout } from './pages/Checkout'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <CoverSection />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
]);


function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
